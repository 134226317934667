.fonts-wrapper {
    padding: 0 !important;
    margin: 0 !important;
    display: flex;
    flex-direction: column;

    .filters {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        background-color: white;
        padding: 10px 10px 0 10px;
    }

    .fonts {
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
        overflow: auto;

        .font-wrapper {
            width: 380px;
            height: 403px;
            background-color: white;
            margin: 12.5px 18px;
            border-radius: 10px;
            padding: 8px;
            text-decoration: none;
            color: @darkGray;

            img {
                height: 342px;
                width: 380px;
            }

            .top {
                display: flex;
                justify-content: space-between;
                align-items: flex-end;
                padding: 4px;

                .font-name {
                    display: flex;
                    align-items: baseline;

                    .name {
                        font-size: 34px;
                        line-height: 31px;

                        &.small {
                            font-size: 27px;
                        }
                    }

                    .new-font {
                        .NewUpdatedFont(#007ac2, 16px);
                    }
                    .updated-font {
                        .NewUpdatedFont(@red, 16px);
                    }
                }

                .font-details {
                    display: flex;
                    flex-direction: column;
                    align-items: flex-end;
                    justify-content: flex-end;
                    font-size: 16px;
                    height: 38px;
                }
            }

            .strip {
                background-color: @lightGray;
                height: 4px;
                margin-bottom: 10px;
            }
        }
    }
}
