.menu .cart {
    display: flex;
    height: fit-content;

    .icon-wrapper {
        display: flex;
        text-decoration: none;

        .icon {
            align-self: center;
            color: @darkGray;
            font-size: 23px;
        }
    }

    .left {
        text-align: end;
        font-size: 14px;
        margin-right: 5px;
        margin-left: 25px;

        .total {
            color: @red;
            font-weight: bold;
        }
    }
}

.user-cart {
    .menu-header,
    .bottom {
        background-color: white;
        padding-left: 40px;
        padding-right: 40px;
    }

    .bottom {
        padding-top: 10px;
        padding-bottom: 10px;

        .table-title {
            margin-top: 30px;
            font-weight: bold;
        }

        .cart-table {
            border-collapse: collapse;

            .header {
                font-weight: bold;

                &.font {
                    width: 300px;
                }
            }

            .empty {
                text-align: center;
            }

            .remove {
                cursor: pointer;
                color: @red;
            }

            .font-item > td {
                vertical-align: baseline;
            }

            tr:nth-last-of-type(2) {
                td {
                    padding-bottom: 15px;
                }
            }

            .cart-total {
                border-top: 1px solid @darkGray;

                td {
                    padding-top: 10px;
                }
            }
        }

        .mandatory {
            color: @red;
        }

        .form {
            margin-top: 10px;

            .form-label {
                width: 180px;
            }

            .purchase-type {
                padding-bottom: 10px;

                .radio {
                    display: flex;
                    align-items: center;

                    label {
                        width: 135px;
                    }

                    input[type='radio'] {
                        margin: 0;
                    }
                }
            }

            .cell {
                width: 330px;
            }

            input[type='text'],
            input[type='email'],
            textarea {
                width: 330px;
                border: 1px solid @darkGray;
            }

            ::placeholder,
            ::-ms-input-placeholder {
                color: @darkGray;
                opacity: 0.8;
            }

            .submit-wrapper {
                width: 105px;
                vertical-align: bottom;

                button {
                    margin-left: 8px;
                    margin-bottom: 5px;
                    border-radius: 5px;
                    border: 1px solid @darkGray;
                    padding: 5px 8px;
                    color: @darkGray;
                    background-color: #fadf8d;
                    font-weight: bold;
                    cursor: pointer;

                    &:disabled {
                        cursor: not-allowed;
                    }
                }
            }
        }
    }
}
