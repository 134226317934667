.home {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.home .wrapper {
  padding: 20px 50px;
  height: 100%;
}
.home .wrapper .synthesis {
  margin-top: 80px;
}
.home .wrapper .synthesis img {
  max-width: 100%;
  height: auto;
  display: block;
}
.home .wrapper .synthesis-footer img {
  margin-top: 12px;
  width: 280px;
  height: auto;
}
.home .wrapper .languages {
  margin-top: 40px;
}
.menu .cart {
  display: flex;
  height: fit-content;
}
.menu .cart .icon-wrapper {
  display: flex;
  text-decoration: none;
}
.menu .cart .icon-wrapper .icon {
  align-self: center;
  color: #626366;
  font-size: 23px;
}
.menu .cart .left {
  text-align: end;
  font-size: 14px;
  margin-right: 5px;
  margin-left: 25px;
}
.menu .cart .left .total {
  color: #c21f32;
  font-weight: bold;
}
.user-cart .menu-header,
.user-cart .bottom {
  background-color: white;
  padding-left: 40px;
  padding-right: 40px;
}
.user-cart .bottom {
  padding-top: 10px;
  padding-bottom: 10px;
}
.user-cart .bottom .table-title {
  margin-top: 30px;
  font-weight: bold;
}
.user-cart .bottom .cart-table {
  border-collapse: collapse;
}
.user-cart .bottom .cart-table .header {
  font-weight: bold;
}
.user-cart .bottom .cart-table .header.font {
  width: 300px;
}
.user-cart .bottom .cart-table .empty {
  text-align: center;
}
.user-cart .bottom .cart-table .remove {
  cursor: pointer;
  color: #c21f32;
}
.user-cart .bottom .cart-table .font-item > td {
  vertical-align: baseline;
}
.user-cart .bottom .cart-table tr:nth-last-of-type(2) td {
  padding-bottom: 15px;
}
.user-cart .bottom .cart-table .cart-total {
  border-top: 1px solid #626366;
}
.user-cart .bottom .cart-table .cart-total td {
  padding-top: 10px;
}
.user-cart .bottom .mandatory {
  color: #c21f32;
}
.user-cart .bottom .form {
  margin-top: 10px;
}
.user-cart .bottom .form .form-label {
  width: 180px;
}
.user-cart .bottom .form .purchase-type {
  padding-bottom: 10px;
}
.user-cart .bottom .form .purchase-type .radio {
  display: flex;
  align-items: center;
}
.user-cart .bottom .form .purchase-type .radio label {
  width: 135px;
}
.user-cart .bottom .form .purchase-type .radio input[type='radio'] {
  margin: 0;
}
.user-cart .bottom .form .cell {
  width: 330px;
}
.user-cart .bottom .form input[type='text'],
.user-cart .bottom .form input[type='email'],
.user-cart .bottom .form textarea {
  width: 330px;
  border: 1px solid #626366;
}
.user-cart .bottom .form ::placeholder,
.user-cart .bottom .form ::-ms-input-placeholder {
  color: #626366;
  opacity: 0.8;
}
.user-cart .bottom .form .submit-wrapper {
  width: 105px;
  vertical-align: bottom;
}
.user-cart .bottom .form .submit-wrapper button {
  margin-left: 8px;
  margin-bottom: 5px;
  border-radius: 5px;
  border: 1px solid #626366;
  padding: 5px 8px;
  color: #626366;
  background-color: #fadf8d;
  font-weight: bold;
  cursor: pointer;
}
.user-cart .bottom .form .submit-wrapper button:disabled {
  cursor: not-allowed;
}
.fonts-wrapper {
  padding: 0 !important;
  margin: 0 !important;
  display: flex;
  flex-direction: column;
}
.fonts-wrapper .filters {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  background-color: white;
  padding: 10px 10px 0 10px;
}
.fonts-wrapper .fonts {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  overflow: auto;
}
.fonts-wrapper .fonts .font-wrapper {
  width: 380px;
  height: 403px;
  background-color: white;
  margin: 12.5px 18px;
  border-radius: 10px;
  padding: 8px;
  text-decoration: none;
  color: #626366;
}
.fonts-wrapper .fonts .font-wrapper img {
  height: 342px;
  width: 380px;
}
.fonts-wrapper .fonts .font-wrapper .top {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  padding: 4px;
}
.fonts-wrapper .fonts .font-wrapper .top .font-name {
  display: flex;
  align-items: baseline;
}
.fonts-wrapper .fonts .font-wrapper .top .font-name .name {
  font-size: 34px;
  line-height: 31px;
}
.fonts-wrapper .fonts .font-wrapper .top .font-name .name.small {
  font-size: 27px;
}
.fonts-wrapper .fonts .font-wrapper .top .font-name .new-font {
  color: #007ac2;
  margin-left: 7px;
  font-size: 16px;
  font-weight: bold;
}
.fonts-wrapper .fonts .font-wrapper .top .font-name .updated-font {
  color: #c21f32;
  margin-left: 7px;
  font-size: 16px;
  font-weight: bold;
}
.fonts-wrapper .fonts .font-wrapper .top .font-details {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-end;
  font-size: 16px;
  height: 38px;
}
.fonts-wrapper .fonts .font-wrapper .strip {
  background-color: #bbbdc0;
  height: 4px;
  margin-bottom: 10px;
}
.font.font-details {
  background-color: white;
  word-break: break-word;
}
.font.font-details .wrapper {
  width: 1510px;
}
.font.font-details .font-name .new-font {
  color: #007ac2;
  margin-left: 7px;
  font-size: 20px;
  font-weight: bold;
}
.font.font-details .font-name .updated-font {
  color: #c21f32;
  margin-left: 7px;
  font-size: 20px;
  font-weight: bold;
}
.font.font-details .font-description {
  text-align: justify;
  margin-bottom: 20px;
  font-size: 18px;
  color: #56575a;
  font-weight: 400;
}
.font.font-details .image-wrapper > img {
  width: 100%;
}
.font.font-details .price-col {
  display: flex;
}
.font.font-details .price-col img {
  width: 60px;
}
.font.font-details .price-col .price-details {
  display: flex;
  flex-direction: column;
  margin-left: 20px;
}
.font.font-details .price-col .price-details .name {
  font-weight: bold;
}
.font.font-details .price-col .price-details .price {
  display: flex;
}
.font.font-details .price-col .price-details .price .final-price {
  margin-left: 3px;
  color: #c21f32;
  font-weight: bold;
}
.font.font-details .price-col .price-details .to-cart {
  color: #c21f32;
  margin-top: 30px;
  display: flex;
  align-items: center;
}
.font.font-details .price-col .price-details .to-cart .cart {
  color: #626366;
  font-size: 30px;
  margin-left: 5px;
  cursor: pointer;
}
.font.font-details .price-col .price-details .to-cart .cart:hover {
  color: #bbbdc0;
}
.font.font-details .downloads .title {
  font-weight: bold;
}
.font.font-details .downloads .link {
  color: #c21f32;
  font-weight: bold;
  text-decoration: none;
}
.about {
  background-color: #626366;
  color: white;
}
.about .wrapper .right {
  margin-left: 35px !important;
}
.about .detail-columns .left .about-text,
.about .detail-columns .right .about-text {
  margin-top: 15px;
}
.about .detail-columns .left .about-text:first-of-type,
.about .detail-columns .right .about-text:first-of-type {
  margin-top: 0;
}
.Container {
  border: 1px solid #bbbdc0;
  border-radius: 5px;
  background-color: white;
  padding: 5px 10px;
}
.filter-wrapper {
  display: flex;
  flex-direction: column;
  position: relative;
  width: fit-content;
  margin-right: 20px;
}
.filter-wrapper .filter {
  border: 1px solid #bbbdc0;
  border-radius: 5px;
  background-color: white;
  padding: 5px 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  margin-bottom: 10px;
}
.filter-wrapper .filter .caret {
  font-size: 19px;
  margin-left: 10px;
}
.filter-wrapper .options {
  border: 1px solid #bbbdc0;
  border-radius: 5px;
  background-color: white;
  padding: 5px 10px;
  position: absolute;
  z-index: 1;
  top: 40px;
  width: calc(100% - 2 * 1px - 2 * 10px);
}
.filter-wrapper .options .option {
  margin: 10px 0;
}
.filter-wrapper .options .option .container {
  display: flex;
  align-items: center;
  position: relative;
  padding-left: 27px;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.filter-wrapper .options .option .container:hover input ~ .checkmark {
  background-color: #bbbdc0;
}
.filter-wrapper .options .option .container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}
.filter-wrapper .options .option .container input:checked ~ .checkmark {
  background-color: #bbbdc0;
}
.filter-wrapper .options .option .container input:checked ~ .checkmark::after {
  display: block;
}
.filter-wrapper .options .option .container .checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 20px;
  width: 20px;
  background-color: #eee;
}
.filter-wrapper .options .option .container .checkmark::after {
  content: '';
  position: absolute;
  display: none;
  left: 6px;
  top: 2px;
  width: 5px;
  height: 10px;
  border: solid #626366;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}
.legal-notice .bold.company {
  color: #fadf8d;
}
.prices {
  display: flex;
  flex-direction: column;
}
.prices .bottom {
  margin-bottom: 15px;
}
.prices .abbreviations {
  margin-bottom: 20px;
}
.prices .language-description {
  display: flex;
}
.prices .language-description .abbreviation {
  font-weight: bold;
  margin-right: 4px;
}
.prices .font-price {
  padding: 1px 0;
}
html,
body,
#root,
.main {
  height: 100%;
  width: 100%;
}
body {
  margin: 0;
  font-family: 'Alegreya Sans', sans-serif;
  font-weight: 300;
  color: #626366;
}
body ::-webkit-scrollbar {
  width: 5px;
}
body ::-webkit-scrollbar-track {
  background: #f1f1f1;
}
body ::-webkit-scrollbar-thumb {
  background: #4b4b4d;
}
body ::-webkit-scrollbar-thumb:hover {
  background: #343435;
}
body .scrollable {
  scrollbar-color: #4b4b4d #f1f1f1;
  scrollbar-width: thin;
  scrollbar-gutter: stable;
}
.main {
  display: flex;
  flex-direction: column;
}
.main .top-strip {
  background-color: #626366;
  height: 18px;
}
.main .middle-strip {
  background-color: #bbbdc0;
  height: 10px;
}
.main .footer-strip {
  background-color: white;
  height: 8px;
}
.main .menu {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  margin: 5px 0;
}
.main .menu .logo {
  padding: 0 40px;
}
.main .menu .menu-actions {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.main .menu .menu-actions .links .menu-item {
  text-decoration: none;
  color: #626366;
  margin: 0 20px;
  font-size: 16px;
}
.main .menu .menu-actions .links .menu-item:last-of-type {
  margin-right: 0;
}
.main .menu .menu-actions .links .menu-item:hover,
.main .menu .menu-actions .links .menu-item.selected {
  font-weight: bold;
}
.main .menu .menu-actions .languages {
  align-self: flex-start;
  font-size: 13px;
}
.main .menu .menu-actions .right {
  display: flex;
  align-self: flex-end;
  margin-bottom: 10px;
}
.main .main-body {
  flex: 1;
  overflow: auto;
  background-color: #bbbdc0;
}
.main .main-body > div {
  margin: 0 70px;
  height: 100%;
  padding: 0 25px;
  overflow: auto;
}
.main .main-body .menu-header {
  font-size: 38px;
  font-weight: 300;
}
.main .footer {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 25px;
  background-color: #626366;
  color: white;
  font-size: 12px;
}
.main .footer a {
  text-decoration: none;
  color: #ff5f00;
  margin-left: 3px;
}
.main .footer a:hover {
  color: white;
}
.languages {
  display: flex;
  font-size: 15px;
  justify-content: flex-end;
}
.languages .language {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 4px;
  cursor: pointer;
}
.languages .language:hover,
.languages .language.selected {
  font-weight: bold;
}
.languages .language:hover .flag.greek,
.languages .language.selected .flag.greek {
  background-image: url('https://d8lc6s7bpjqw2.cloudfront.net/anagrafi/v2/images/general/gr_active.png');
}
.languages .language:hover .flag.english,
.languages .language.selected .flag.english {
  background-image: url('https://d8lc6s7bpjqw2.cloudfront.net/anagrafi/v2/images/general/en_active.png');
}
.languages .language .flag {
  width: 24px;
  height: 16px;
  background-size: contain;
  background-repeat: no-repeat;
}
.languages .language .flag.greek {
  background-image: url('https://d8lc6s7bpjqw2.cloudfront.net/anagrafi/v2/images/general/gr_inactive.png');
}
.languages .language .flag.english {
  background-image: url('https://d8lc6s7bpjqw2.cloudfront.net/anagrafi/v2/images/general/en_inactive.png');
}
.scrollable-container .wrapper {
  margin: auto;
  width: 100%;
  display: flex;
  flex-direction: column;
  height: 100%;
}
.scrollable-container .wrapper .top .border {
  display: flex;
  margin-bottom: 8px;
}
.scrollable-container .wrapper .top .border .left,
.scrollable-container .wrapper .top .border .right {
  height: 6px;
  background-color: #bbbdc0;
}
.scrollable-container .wrapper .top .single-border {
  margin-bottom: 8px;
  height: 6px;
  background-color: #bbbdc0;
}
.scrollable-container .wrapper .bottom {
  flex: 1;
  overflow: auto;
}
.scrollable-container .wrapper .bottom .detail-columns {
  display: flex;
}
.scrollable-container .wrapper .bottom .detail-columns:last-of-type {
  margin-bottom: 10px;
}
.scrollable-container .wrapper .bottom .detail-columns:last-of-type .left,
.scrollable-container .wrapper .bottom .detail-columns:last-of-type .right {
  border-top: 6px solid #bbbdc0;
  padding-top: 8px;
}
.scrollable-container .wrapper .bottom .detail-columns:last-of-type .left.no-border,
.scrollable-container .wrapper .bottom .detail-columns:last-of-type .right.no-border {
  border-top: unset;
}
.scrollable-container .wrapper .right {
  margin-left: 20px;
}
.scrollable-container .wrapper .left,
.scrollable-container .wrapper .right {
  width: 745px;
}
.custom-services,
.legal-notice,
.contact,
.useful-info,
.support,
.prices {
  background-color: #626366;
  color: white;
  font-weight: 400;
}
.custom-services .bold,
.legal-notice .bold,
.contact .bold,
.useful-info .bold,
.support .bold,
.prices .bold {
  font-weight: bold;
}
.useful-info .bottom {
  font-family: Arial, Helvetica, sans-serif;
  font-size: 15px;
}
.useful-info .title {
  font-size: 17px;
}
.custom-services .bold,
.useful-info .bold,
.support .bold,
.prices .bold {
  color: #fadf8d;
}
.custom-services .title,
.useful-info .title,
.support .title,
.prices .title {
  font-weight: bold;
}
.link {
  color: white;
  text-decoration: none;
}
.link:hover {
  color: #faa671;
}
