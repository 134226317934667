.font.font-details {
    background-color: white;
    word-break: break-word;

    .wrapper {
        width: 1510px;
    }

    .font-name .new-font {
        .NewUpdatedFont(#007ac2, 20px);
    }
    .font-name .updated-font {
        .NewUpdatedFont(@red, 20px);
    }

    .font-description {
        text-align: justify;
        margin-bottom: 20px;
        font-size: 18px;
        color: #56575a;
        font-weight: 400;
    }

    .image-wrapper > img {
        width: 100%;
    }

    .price-col {
        display: flex;

        img {
            width: 60px;
        }

        .price-details {
            display: flex;
            flex-direction: column;
            margin-left: 20px;

            .name {
                font-weight: bold;
            }

            .price {
                display: flex;

                .final-price {
                    margin-left: 3px;
                    color: @red;
                    font-weight: bold;
                }
            }

            .to-cart {
                color: @red;
                margin-top: 30px;
                display: flex;
                align-items: center;

                .cart {
                    color: @darkGray;
                    font-size: 30px;
                    margin-left: 5px;
                    cursor: pointer;

                    &:hover {
                        color: @lightGray;
                    }
                }
            }
        }
    }

    .downloads {
        .title {
            font-weight: bold;
        }

        .link {
            color: @red;
            font-weight: bold;
            text-decoration: none;
        }
    }
}
