.about {
    background-color: @darkGray;
    color: white;

    .wrapper .right {
        margin-left: 35px !important;
    }

    .detail-columns .left,
    .detail-columns .right {
        .about-text {
            margin-top: 15px;

            &:first-of-type {
                margin-top: 0;
            }
        }
    }
}
