.prices {
    display: flex;
    flex-direction: column;

    .bottom {
        margin-bottom: 15px;
    }

    .abbreviations {
        margin-bottom: 20px;
    }

    .language-description {
        display: flex;

        .abbreviation {
            font-weight: bold;
            margin-right: 4px;
        }
    }

    .font-price {
        padding: 1px 0;
    }
}
