@lightGray: #bbbdc0;
@darkGray: #626366;
@red: #c21f32;
@scrollbarBackground: #f1f1f1;
@scrollbarColor: #4b4b4d;
@scrollbarHoverColor: #343435;

.Scrollbar(@track, @thumb, @hover) {
    ::-webkit-scrollbar {
        width: 5px;
    }

    ::-webkit-scrollbar-track {
        background: @track;
    }

    ::-webkit-scrollbar-thumb {
        background: @thumb;

        &:hover {
            background: @hover;
        }
    }

    .scrollable {
        // for Firefox
        scrollbar-color: @scrollbarColor @scrollbarBackground;
        scrollbar-width: thin;
        scrollbar-gutter: stable;
    }
}

.NewUpdatedFont(@color, @fontSize) {
    color: @color;
    margin-left: 7px;
    font-size: @fontSize;
    font-weight: bold;
}
