@border: 1px;
@padding: 10px;

.Container {
    border: @border solid @lightGray;
    border-radius: 5px;
    background-color: white;
    padding: 5px @padding;
}

.filter-wrapper {
    display: flex;
    flex-direction: column;
    position: relative;
    width: fit-content;
    margin-right: 20px;

    .filter {
        .Container;
        display: flex;
        align-items: center;
        justify-content: space-between;
        cursor: pointer;
        margin-bottom: 10px;

        .caret {
            font-size: 19px;
            margin-left: 10px;
        }
    }

    .options {
        .Container;
        position: absolute;
        z-index: 1;
        top: 40px;
        width: calc(100% - 2 * @border - 2 * @padding);

        .option {
            margin: 10px 0;

            .container {
                display: flex;
                align-items: center;
                position: relative;
                padding-left: 27px;
                cursor: pointer;
                -webkit-user-select: none;
                -moz-user-select: none;
                -ms-user-select: none;
                user-select: none;

                &:hover input ~ .checkmark {
                    background-color: @lightGray;
                }

                input {
                    position: absolute;
                    opacity: 0;
                    cursor: pointer;
                    height: 0;
                    width: 0;

                    &:checked ~ .checkmark {
                        background-color: @lightGray;

                        &::after {
                            display: block;
                        }
                    }
                }

                .checkmark {
                    position: absolute;
                    top: 0;
                    left: 0;
                    height: 20px;
                    width: 20px;
                    background-color: #eee;

                    &::after {
                        content: '';
                        position: absolute;
                        display: none;
                        left: 6px;
                        top: 2px;
                        width: 5px;
                        height: 10px;
                        border: solid @darkGray;
                        border-width: 0 3px 3px 0;
                        -webkit-transform: rotate(45deg);
                        -ms-transform: rotate(45deg);
                        transform: rotate(45deg);
                    }
                }
            }
        }
    }
}
