.home {
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .wrapper {
        padding: 20px 50px;
        height: 100%;

        .synthesis {
            margin-top: 80px;

            img {
                max-width: 100%;
                height: auto;
                display: block;
            }
        }

        .synthesis-footer img {
            margin-top: 12px;
            width: 280px;
            height: auto;
        }

        .languages {
            margin-top: 40px;
        }
    }
}
